<template>
  <router-link
    class="d-inline-block"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-img
      :src="`/img/logos/genio-logo-${theme.isDark ? 'dark' : 'light'}.svg`"
      :alt="$t('logo')"
      contain
      class="shrink"
      max-width="148"
      width="148"
    />
  </router-link>
</template>

<script>
  export default {
    name: 'VuetifyLogo',

    inject: ['theme'],
  }
</script>
