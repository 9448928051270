import vuetifyColors from 'vuetify/lib/util/colors'

export const blue = {
  base: '#6185DB',
  lighten1: '#F9FBFF',
  lighten2: '#EAEFFA',
  lighten3: '#A2B4DF',
  lighten4: '#8AA5E8',
  darken1: '#4964A4',
  darken2: '#30426E',
  darken3: '#101038',
  darken4: '#585874',
}

export const purple = {
  base: '#7D67BC',
  lighten1: '#FCFBFF',
  lighten2: '#E4E3FF',
  lighten3: '#BEAEED',
  darken1: '#5E4D8D',
  darken2: '#3F345E',
  accent1: '#CDCCE6',
}

export const red = {
  base: '#F03738',
  lighten1: '#FFD4D4',
  lighten2: '#F35F5F',
  darken1: '#B4292A',
  darken2: '#781C1C',
}

export const green = {
  base: '#13CE66',
  lighten1: '#BDECD2',
  lighten2: '#7BD9A5',
  darken1: '#0FA552',
  darken2: '#0A6733',
}

export const yellow = {
  base: '#FAD901',
  lighten1: '#F7EFB8',
  lighten2: '#EFDE72',
  darken1: '#BCA301',
  darken2: '#7D6D00',
}

export const grey = {
  base: '#737373',
  lighten1: '#BABABA',
  lighten2: '#D9D9D9',
}

export const shades = vuetifyColors.shades
export const colors = { blue, purple, red, green, yellow, grey }
export default { ...colors, shades }
// Material + GENIO Palette
// export default Object.assign({ ...vuetifyColors }, colors)
