import {
  blue as primary,
  purple as secondary,
  red as error,
  yellow as warning,
  green as success,
  shades,
} from './colors'

export const light = {
  tertiary: '#E57373',
  text: shades.white,
  background: shades.white,

  primary,
  secondary,
  accent: secondary.accent1,
  info: primary.darken1,
  warning,
  error,
  success,
}

export default light
