module.exports = [
  {
    title: 'English',
    locale: 'en',
  },
  {
    title: '日本語',
    locale: 'ja-JP',
    alternate: 'ja',
  },
  {
    title: '简体中文',
    locale: 'zh-CN',
    alternate: 'zh-Hans',
  },
  {
    title: 'Help translate',
    locale: 'eo-UY',
  },
]
